// Used in the Election Stats: Race Results Block. Enables search when the input field is enabled.
(function(w, d) {
    w.electionstats_search_config.forEach(function(o) {
        let blockId = o.block_id;
        let electionRaceSearchInput = document.getElementById(`election-race-searchbar-${blockId}`)
        electionRaceSearchInput.onkeyup = function() {
                let input = document.getElementById(`election-race-searchbar-${blockId}`).value;
                input = input.toLowerCase();
                let electionList = document.getElementsByClassName(`election-race-item-${blockId}`);
                
                for (i = 0; i < electionList.length; i++) {
                    if (!electionList[i].innerHTML.toLowerCase().includes(input)) {
                        electionList[i].classList.add("hide");
                    }
                    else {
                        electionList[i].classList.remove("hide");                 
                    }
                }
            }
    })
})(window, document)